// .cache/gatsby-mdx/wrapper-components/{wrapper-filepath-hash}-{scope-hash}.js
import React from 'react';
import { MDXScopeProvider } from 'gatsby-mdx/context';

import __mdxScope_0 from '/Users/jmcglone/GitHub/mlibrary/heartofdarkness/.cache/gatsby-mdx/mdx-scopes-dir/3010b3badc54a9dfa4a4c80e419a41b2.js';

import OriginalWrapper from '/Users/jmcglone/GitHub/mlibrary/heartofdarkness/src/templates/content-pages.js';

import { graphql } from 'gatsby';

// pageQuery, etc get hoisted to here
export const pageQuery = graphql`
  query($id: String!, $key: String!, $parentKey: String) {
    post: mdx(id: { eq: $id }) {
      id
      rawBody
      code {
        body
      }
      wordCount {
        words
      }
      frontmatter {
        title
      }
    }
    childPages: allMdx(filter: { frontmatter: { parentKey: { eq: $key } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            key
            parentKey
            sortOrder
          }
        }
      }
    }
    siblingPages: allMdx(
      filter: {
        frontmatter: { parentKey: { eq: $parentKey }, key: { ne: $key } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    allMdx: allMdx {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            key
            parentKey
            sortOrder
          }
        }
      }
    }
  }
`;

export default ({children, ...props}) => <MDXScopeProvider __mdxScope={{...__mdxScope_0}}>
  <OriginalWrapper
    {...props}
  >
    {children}
  </OriginalWrapper>
</MDXScopeProvider>